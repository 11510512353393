import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import Loadable from "react-loadable";
// import "./App.css";

function AppLoadingComponent({ error }) {
    return <div></div>;
}
const Index = Loadable({
    loader: () => import("./pages/index/index.page.js"),
    loading: AppLoadingComponent,
    delay: 300
});
const TemperatureRecorderList = Loadable({
    loader: () =>
        import("./pages/temperatureRecorderList/temperatureRecorderList.page.js"),
    loading: AppLoadingComponent,
    delay: 300
});
const Contact = Loadable({
    loader: () => import("./pages/contact/Contact.page.js"),
    loading: AppLoadingComponent,
    delay: 300
});

class App extends Component {
    constructor() {
        super();
        this.state = {
            title: "天悦生科技有限公司"
        };
    }
    render() {
        return (
            <HashRouter>
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={ Index } />
                        <Route path="/home" component={ Index } />
                        <Route
                            path="/temperatureRecorderList"
                            component={ TemperatureRecorderList }
                        />
                        <Route path="/contact" component={ Contact } />

                        <Redirect to="/home" />
                    </Switch>
                </div>
            </HashRouter>
        );
    }
}

export default App;
