var initialState ={
    a: 1,
    b: 2
}
export default (state = initialState,action) => {
    switch (action.type) {
        case "INCREMENT":
            state.a += 1;
            console.log(state)
            // 当 state 变化时需要返回全新的对象，而不是修改传入的参数
            return {
                ...state
            }
        case "DECREMENT":
            state.b += 1;
            console.log(state)
            return  {
                ...state
            }
        default:return  {
            ...state
        }
    }
};
